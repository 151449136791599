import Vue from 'vue'
import App from './App.vue'

// helper
import "@/assets/styles/index.scss";
import "@/utils/mixin";
import "@/utils/filter";

// setting
import router from '@/routers/index'
import store from '@/stores/index'

// modules
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

import VueSweetalert2 from 'vue-sweetalert2'
Vue.use(VueSweetalert2)

// setup axios instance
import cookie from 'js-cookie'
import axios from "axios";
const axiosOptions = {
  baseURL: process.env.VUE_APP_BASE_URL_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-BLOCKCHAIN": "true",
  },
};

if (cookie.get("token") !== undefined) {
  const token = JSON.parse(cookie.get("token"));
  store.commit("SET_TOKEN", {
    type: "accessToken",
    token: token.accessToken,
  });
  store.commit("SET_TOKEN", {
    type: "refreshToken",
    token: token.refreshToken,
  });
  const profile = JSON.parse(cookie.get("userData"));
  store.commit("SET_USERDATA", profile);

  axiosOptions.headers.Authorization = `Bearer ${token.accessToken}`;
}
const axiosInstance = axios.create(axiosOptions);
Vue.prototype.$http = axiosInstance;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
