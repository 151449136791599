const getters = {
  isLoggedIn(state) {
    return state.userData ? true : false;
  },
  accessToken(state) {
    return state.token.accessToken;
  },
  activeToken(state) {
    return state.token.activeToken;
  },
  codeToken(state) {
    return state.token.codeToken;
  },
  refreshToken(state) {
    return state.token.refreshToken;
  },
  resetPasswordToken(state) {
    return state.token.resetPasswordToken;
  },
}

export default getters
