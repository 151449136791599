import Vue from 'vue'
import Vuex from 'vuex'

import state from './module/state'
import getters from './module/getters'
import mutations from './module/mutations'
import actions from './module/actions'

Vue.use(Vuex)

const store = new Vuex.Store({
  getters,
  mutations,
  state,
  actions, 
  strict: process.env.NODE_ENV !== 'production',
})

export default store

