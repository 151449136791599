const routes = [
  // geust page
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/auth/Login.vue'),
    meta: {
      pageType: 'guest'
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/pages/auth/ForgotPassword.vue'),
    meta: {
      pageType: 'guest'
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/pages/auth/ResetPassword.vue'),
    meta: {
      pageType: 'guest'
    }
  },
  // authenticated page
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/pages/dashboard/Home.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/admin',
    name: 'admin-management',
    component: () => import('@/views/pages/admin/List.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/admin/create',
    name: 'create-admin',
    component: () => import('@/views/pages/admin/Create.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/admin/edit/:id',
    name: 'edit-admin',
    component: () => import('@/views/pages/admin/Edit.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/village',
    name: 'village-management',
    component: () => import('@/views/pages/village/List.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/village/create',
    name: 'create-village',
    component: () => import('@/views/pages/village/Create.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/village/edit/:id',
    name: 'edit-village',
    component: () => import('@/views/pages/village/Edit.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/solar-panel-device',
    name: 'solar-panel-device-management',
    component: () => import('@/views/pages/solar-panel-device/List.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/solar-panel-device/create',
    name: 'create-solar-panel-device',
    component: () => import('@/views/pages/solar-panel-device/Create.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/solar-panel-device/edit/:id',
    name: 'edit-solar-panel-device',
    component: () => import('@/views/pages/solar-panel-device/Edit.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/token',
    name: 'token-management',
    component: () => import('@/views/pages/token/List.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/device',
    name: 'device-management',
    component: () => import('@/views/pages/device/List.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/usage',
    name: 'meter-usage',
    component: () => import('@/views/pages/usage/List.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/token/create',
    name: 'create-token',
    component: () => import('@/views/pages/token/Create.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/token/edit/:id',
    name: 'edit-token',
    component: () => import('@/views/pages/token/Edit.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/user',
    name: 'user-management',
    component: () => import('@/views/pages/user/List.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/user/:id',
    name: 'detail-user',
    component: () => import('@/views/pages/user/Detail.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/transaction',
    name: 'transaction-management',
    component: () => import('@/views/pages/transaction/List.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  {
    path: '/transaction/:id',
    name: 'detail-transaction',
    component: () => import('@/views/pages/transaction/Detail.vue'),
    meta: {
      pageType: 'authenticated'
    }
  },
  // error page
  {
    path: '/pages/404',
    name: 'Page-404',
    component: () => import('@/views/pages/error/error-404'),
    meta: {
      pageType: 'error',
    }
  },
  {
    path: '/pages/500',
    name: 'Page-500',
    component: () => import('@/views/pages/error/error-500'),
    meta: {
      pageType: 'error',
    }
  },
  {
    path: '/pages/maintenance',
    name: 'Maintenance',
    component: () => import('@/views/pages/error/maintenance'),
    meta: {
      pageType: 'error',
    }
  },
]

export default routes;