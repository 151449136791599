import Vue from 'vue'
import VueRouter from 'vue-router' 
import VueMeta from 'vue-meta'
import routes from './routes.js'
import store from '@/stores/index.js'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.pageType === 'authenticated' && store.state.userData === null) {
    next('/login')
  } else if (to.meta.pageType === 'guest' && store.state.userData !== null) {
    next('/')
  } else if (to.name === null) {
    next('/pages/404')
  } else {
    next()
  }
})

export default router