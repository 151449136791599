import Vue from "vue";
import moment from "moment";

Vue.mixin({
  data() {
    return {
      moneyProperty: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  mounted() {
    // Fix Vertical Height //
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    // Modal //
    const openEls = document.querySelectorAll("[data-open]");
    const closeEls = document.querySelectorAll("[data-close]");
    const isVisible = "is-visible";

    for (const el of closeEls) {
      el.addEventListener("click", function () {
        if (document.querySelector(".modal.is-visible")) {
          document
            .querySelector(".modal.is-visible")
            .classList.remove(isVisible);
        }
      });
    }

    for (const el of openEls) {
      el.addEventListener("click", function (e) {
        const modalId = this.dataset.open;
        document.getElementById(modalId).classList.add(isVisible);
        e.preventDefault();
      });
    }

    document.addEventListener("click", (e) => {
      if (e.target == document.querySelector(".modal.is-visible")) {
        document.querySelector(".modal.is-visible").classList.remove(isVisible);
      }
    });
  },
  methods: {
    errorResponse(error) {
      if (error.response) {
        if (error.response.data.data) {
          if (error.response.data.data[0].message !== undefined) {
            this.$store.commit("SHOW_ALERT", {
              type: "error",
              message: error.response.data.data[0].message,
            });
          }
        } else {
          this.$store.commit("SHOW_ALERT", {
            type: "error",
            message: error.response.data.message,
          });
        }
      }
    },
    formatDate(datetime, format) {
      return moment(datetime).format(format);
    },
    convertToRupiah(angka) {
      var rupiah = "";
      var angkarev = angka.toString().split("").reverse().join("");
      for (var i = 0; i < angkarev.length; i++)
        if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + ".";
      return rupiah
        .split("", rupiah.length - 1)
        .reverse()
        .join("");
    },
    convertToAngka(rupiah) {
      if (rupiah == "") {
        return 0;
      } else {
        return parseInt(rupiah.replace(/,.*|[^0-9]/g, ""), 10);
      }
    },
    disableInputMin(event) {
      if (event.code === "Minus") {
        event.preventDefault();
      }
    },
    disableInputAlfabhet(event) {
      if (event.keyCode < 48 || event.keyCode > 57) {
        event.preventDefault();
      }
    },
    disableInputSpace(event) {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    },
  },
});

export default Vue;
