const mutations = {
  SET_USERDATA(state, user) {
    state.userData = user;
  },
  SET_TOKEN(state, payload) {
    if (payload.type === "accessToken") {
      state.token.accessToken = payload.token;
    } else {
      state.token.refreshToken = payload.token;
    }
  },
}

export default mutations
